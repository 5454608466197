import React, { useEffect, useState } from "react";
import dots from "../../assets/icons/DotsThree.svg";
import google from "../../assets/icons/Google.svg";
import microsoft from "../../assets/icons/Microsoft.svg";
import Integrate from "../SideMenus/Integrate";
import BackgroundBlur from "../sub-components/Blur";
import { ReactComponent as Disconnected } from "../../assets/icons/Disconnected.svg";
import { ReactComponent as Connected } from "../../assets/icons/Connected.svg";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../WindowSize/WindowDimensions";
import { MenuItem } from "@mui/material";
import { ReactComponent as IntegrationIcon } from "../../assets/icons/IntegrationIcon.svg";
import { StyledMenu } from "../Menus/Menu";
import { toggleSyncMenu } from "../../redux/slices/identityProviderSlice";

export default function ConnectionCard(props) {
  const dispatch = useDispatch();
  const [sideMenu, setSideMenu] = useState(false);
  const [actionMenu, setActionMenu] = useState({ menu: null, click: 0 });
  const [isAnyConnected, setIsAnyConnected] = useState(false);
  const { IdentityProvders } = useSelector(
    (state) => state.identityProviderReducer.ProviderConnectionInfo,
  );
  const { setShowSyncMenu } = props;

  const mediaQuery = useWindowDimensions();

  useEffect(() => {
    if (IdentityProvders.connected) {
      setIsAnyConnected(true);
    }
  }, [IdentityProvders]);

  const arrayToReturn = [];

  const handleActionMenu = (id) => {
    setActionMenu({
      menu: true,
      click: id,
    });
    if (actionMenu.click === id && !(actionMenu.menu === null)) {
      setActionMenu({
        menu: null,
        click: id,
      });
    }
  };
  const [sideMenuData, setSideMenuData] = useState("");
  const handleSideMenu = (data) => {
    setSideMenuData(data);
    setSideMenu(true);
  };
  const handleSideMenuClose = () => {
    setSideMenu(false);
  };

  const rows = props?.cardData
    ?.filter((val) => {
      if (props.identityProviders) {
        return val;
      } else if (props?.inputValue === "") {
        return val;
      } else if (
        val?.name?.toLowerCase()?.includes(props?.inputValue?.toLowerCase())
      ) {
        return val;
      }
    })
    .filter((val) => {
      if (props?.allFilters?.length > 0) {
        const filtersArr = Object.entries(props.filters);
        return filtersArr.every(([key, values]) => values.includes(val[key]));
      } else {
        return val;
      }
    })
    .map((data) => {
      return data;
    });

  const rowToMap = arrayToReturn.length > 0 ? arrayToReturn : rows;

  useEffect(() => {
    if (IdentityProvders.connected) {
      setIsAnyConnected(true);
    }
  }, [IdentityProvders]);

  if (props.integrations) {
    return (
      <>
        <>
          <Integrate
            data={sideMenuData}
            sideMenu={sideMenu}
            handleConnect={props.handleConnect}
            handleSideMenuClose={handleSideMenuClose}
          />
          {sideMenu && <BackgroundBlur />}
        </>

        <div className="integrations-cards-container">
          {rowToMap.map((data) => {
            return (
              <div
                key={data.id}
                className="integration-card"
                onClick={() => {
                  // props.handleActionMenu(event);
                  // handleSideMenu(event);
                  props.handleCurrentRow(data);
                  props.handleConnection();
                  props.handleActionMenuClose();
                }}
                style={{ cursor: "pointer" }}
              >
                <div className="card-action-button">
                  <IntegrationIcon
                    onClick={() => {
                      // props.handleActionMenu(event);
                      // handleSideMenu(event);
                      props.handleCurrentRow(data);
                      props.handleConnection();
                      props.handleActionMenuClose();
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={props.anchorEl}
                  open={props.open}
                  onClose={props.handleActionMenuClose}
                >
                  <MenuItem
                    disableRipple
                    onClick={() => {
                      props.handleConnection();
                      props.handleActionMenuClose();
                    }}
                  >
                    <span className="s2 grey8">Connect</span>
                  </MenuItem>
                </StyledMenu>
                <img
                  src={data.image_url}
                  width="60"
                  height="30"
                  className="card-connector-image"
                />
                <div
                  style={{
                    height: "100px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className="head6 grey8 card-connector-name">
                    {data.name}
                  </div>
                  <div className="b1 grey8" style={{ textAlign: "center" }}>
                    {data.category}
                  </div>

                  <div
                    className="d-flex flex-cardData align-items-center"
                    style={{ paddingTop: "15px" }}
                  >
                    <>
                      {Object.prototype.hasOwnProperty.call(
                        props.toolsStatus,
                        data?.name?.toLowerCase(),
                      ) ? (
                        <>
                          <Connected style={{ marginRight: "10px" }} />
                          <span className="b2 grey6">Connected</span>
                        </>
                      ) : (
                        <>
                          <Disconnected style={{ marginRight: "10px" }} />
                          <span className="b2 grey6">Not Connected</span>
                        </>
                      )}
                    </>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }
  return (
    <>
      <Integrate
        data={sideMenuData}
        sideMenu={sideMenu}
        handleConnect={props.handleConnect}
        handleSideMenuClose={handleSideMenuClose}
      />
      {sideMenu && <BackgroundBlur />}

      <div className="connection-card-container">
        {rows.map((data) => {
          const handleConnection = () => {
            props.handleSideMenu(data);
            setActionMenu(false);
          };
          const closeActionMenu = () => {
            setActionMenu(false);
          };
          const isConnected =
            data.tool !== IdentityProvders.providersName && isAnyConnected; //false initially
          const isDisabled = isConnected && "#6C757D";
          return (
            <div
              key={data.id}
              className="connection-card"
              onClick={() => {
                if (data.status.toLowerCase() === "connected") {
                  dispatch(toggleSyncMenu());
                  closeActionMenu();
                  setShowSyncMenu(true);
                } else if (!isConnected) {
                  handleConnection();
                }
              }}
              style={{
                cursor: isConnected ? "not-allowed" : "pointer",
                // pointerEvents: isConnected ? "none" : "auto",
              }}
            >
              {data.tool === "GSuite" && (
                <img src={google} className="card-connector-image" />
              )}
              {data.tool === "Microsoft Azure" && (
                <img src={microsoft} className="card-connector-image" />
              )}

              <div
                style={{
                  height: "100px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  className="head6 card-connector-name"
                  style={{ color: isDisabled }}
                >
                  {data.tool}
                </div>

                <div className="b2" style={{ color: isDisabled }}>
                  {data.category}
                </div>

                <div
                  className="d-flex flex-cardData align-items-center"
                  style={{ paddingTop: "15px" }}
                >
                  <div className="b2" style={{ color: isDisabled }}>
                    {data.status.toLowerCase() === "connected" ? (
                      <Connected style={{ marginRight: "5px" }} />
                    ) : (
                      <Disconnected style={{ marginRight: "5px" }} />
                    )}
                    {data.status}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
