import React, { useEffect, useState } from "react";
import Button from "../../components/sub-components/Button";
import { ReactComponent as Arrow_left } from "../../assets/icons/ArrowLeft.svg";
import { ReactComponent as Close } from "../../assets/icons/X.svg";
import Field from "../sub-components/Field";
import { useDispatch, useSelector } from "react-redux";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import Geocode from "react-geocode";
import PlacesAutocomplete from "react-places-autocomplete";
import {
  handleStripeFormChange,
  handleStripeFormSelectChange,
  validateStripeForm,
} from "../../redux/slices/authSlice";
import {
  IsAddNewCardInStore,
  createStripePaymentMethod,
  handleSideMenu,
  togglePaymentMethodExpiredSideMenu,
} from "../../redux/slices/billingSlice";
import { getData } from "country-list";
import CustomSelect from "../sub-components/Select";

export default function AddCard(props) {
  // const { tool } = props.data;
  const { sideMenu } = props;

  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const form = useSelector((state) => state.authReducer.stripePaymentForm);
  const subscription = useSelector((state) => state.authReducer.subscription);
  const {
    isPaymentMethodExpiredSideMenu,
    isPaymentMethodExpired,
    IsAddNewCard,
  } = useSelector((state) => state.billingsReducer);

  const errors = useSelector(
    (state) => state.authReducer.stripePaymentFormErrors,
  );
  const couponCode = useSelector((state) => state.authReducer.couponCode);
  const user = useSelector((state) => state.authReducer.user);
  const { loading } = useSelector((state) => state.billingsReducer);
  const [country, setCountry] = useState(null);
  const [countriesList, setCountriesList] = useState([]);

  useEffect(() => {
    if (countriesList.length === 0) {
      setCountriesList(getData());
    }
  }, []);

  const cardNumberStyle = {
    style: {
      base: {
        fontSize: "17px",
        "::placeholder": {
          color: "#f8f9fa",
        },
        lineHeight: "45px",
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
    showIcon: true,
  };

  const cardStyle = {
    style: {
      base: {
        fontSize: "17px",
        "::placeholder": {
          color: "#f8f9fa",
        },
        lineHeight: "45px",
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const validateForm = () => {
    if (!form.name) {
      dispatch(
        validateStripeForm({
          name: "name",
          value: "Name is required",
        }),
      );
    }
    if (!form.address) {
      dispatch(
        validateStripeForm({
          name: "address",
          value: "Address is required",
        }),
      );
    }
    if (!country) {
      dispatch(
        validateStripeForm({
          name: "country",
          value: "Country is required",
        }),
      );
    }
    if (!form.postalCode) {
      dispatch(
        validateStripeForm({
          name: "postalCode",
          value: "Postal Code is required",
        }),
      );
    }
    if (!form.city) {
      dispatch(
        validateStripeForm({
          name: "city",
          value: "City is required",
        }),
      );
    }
    if (!form.state) {
      dispatch(
        validateStripeForm({
          name: "state",
          value: "State / Province is required",
        }),
      );
    }
    if (form.name) {
      dispatch(
        validateStripeForm({
          name: "name",
          value: false,
        }),
      );
    }
    if (form.address) {
      dispatch(
        validateStripeForm({
          name: "address",
          value: false,
        }),
      );
    }
    if (country) {
      dispatch(
        validateStripeForm({
          name: "country",
          value: false,
        }),
      );
    }
    if (form.postalCode) {
      dispatch(
        validateStripeForm({
          name: "postalCode",
          value: false,
        }),
      );
    }
  };

  const handleSubmit = () => {
    validateForm();

    if (
      form.name &&
      form.address &&
      country &&
      form.postalCode &&
      form.city &&
      form.state &&
      !errors.cardNumber &&
      !errors.cardExpiry &&
      !errors.cardCVC
    ) {
      // dispatch(
      //   createStripePaymentMethod(
      //     stripe,
      //     elements,
      //     CardNumberElement,
      //     data: form,
      //     IsAddNewCard,
      //     subscription,
      //     // stripePricings,
      //     user.tenantId,
      //     couponCode,
      //   ),
      // );
      dispatch(
        createStripePaymentMethod({
          stripe: stripe,
          elements: elements,
          CardNumberElement: CardNumberElement,
          data: form, // Assuming `form` is where you collect the card details and other info.
          country: country,
          IsAddNewCard: IsAddNewCard,
        }),
      );
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(handleStripeFormChange({ name: name, value: value }));
  };

  const handleSelectChange = (selected) => {
    dispatch(handleStripeFormSelectChange(selected));
  };

  const handleAddressChange = (address) => {
    dispatch(handleStripeFormChange({ name: "address", value: address }));
  };

  const handleAddressSelect = (address) => {
    Geocode.fromAddress(address)
      .then((response) => {
        let city = "",
          state = "",
          streetNumber = "",
          postalCode = "",
          route = "";
        const country = { label: "", value: "" };

        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "street_number":
                streetNumber =
                  response.results[0].address_components[i].long_name;
                break;
              case "route":
                route = response.results[0].address_components[i].long_name;
                break;
              case "postal_code":
                postalCode =
                  response.results[0].address_components[i].long_name;
                break;
              case "country":
                country.label =
                  response.results[0].address_components[i].long_name;
                country.value =
                  response.results[0].address_components[i].short_name;
                break;
            }
          }
        }
        handleChange({ name: "address", value: streetNumber + " " + route });
        handleChange({ name: "city", value: city });
        handleChange({ name: "state", value: state });
        handleChange({ name: "postalCode", value: postalCode });
        handleSelectChange(country);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (isPaymentMethodExpiredSideMenu === true) {
      document.body.style.overflow = "hidden";
    } else if (isPaymentMethodExpiredSideMenu === false) {
      document.body.style.overflow = "unset";
    }
  }, [isPaymentMethodExpiredSideMenu]);

  return (
    <div
      className={`side-menu-box ${
        (sideMenu === true && !isPaymentMethodExpired) ||
        (isPaymentMethodExpired && isPaymentMethodExpiredSideMenu)
          ? "open border"
          : "side-menu-box border"
      }`}
    >
      <div className="d-flex align-items-center justify-content-between">
        {isPaymentMethodExpired ? (
          <div className="h5">
            <Close
              fill="black"
              stroke="black"
              strokeWidth="2"
              style={{ marginRight: "20px", width: "26px" }}
              onClick={() => dispatch(togglePaymentMethodExpiredSideMenu())}
            />
            <span className="head5 grey9" style={{ marginLeft: "10px" }}>
              Your Payment Method has Expired!
            </span>
            <div className="b1 grey7" style={{ marginLeft: "45px" }}>
              To continue your subscription, please enter new card details below
            </div>

            <div className="head5 grey9" style={{ marginTop: "30px" }}>
              Add Card
            </div>
          </div>
        ) : (
          <span className="head5 grey9">
            <Arrow_left
              stroke="black"
              strokeWidth="2"
              style={{ marginRight: "20px", width: "26px", cursor: "pointer" }}
              onClick={
                IsAddNewCard
                  ? () => dispatch(IsAddNewCardInStore())
                  : () => dispatch(handleSideMenu())
              }
            />
            Add Card
          </span>
        )}

        <div
          className="d-flex align-items-center flex-row-reverse"
          style={{ width: "fit-content" }}
        >
          <div className="integration-menu-buttons">
            <Button
              buttonFilledFull
              title="Add"
              style={{ width: "104px", height: "40px", fontWeight: "400" }}
              loading={loading}
              typography="s1 grey1"
              onClick={() => {
                handleSubmit();
              }}
            />
          </div>
          <div
            style={{ paddingRight: "15px" }}
            className="integration-menu-buttons"
          >
            <Button
              buttonHollowLg
              typography="s1 grey6"
              title="Cancel"
              style={{ height: "40px" }}
              onClick={
                isPaymentMethodExpired
                  ? () => dispatch(togglePaymentMethodExpiredSideMenu())
                  : IsAddNewCard
                    ? () => dispatch(IsAddNewCardInStore())
                    : () => dispatch(handleSideMenu())
              }
            />
          </div>
        </div>
      </div>

      <div className="row" style={{ paddingTop: "40px", textAlign: "left" }}>
        <div className="col-lg-12">
          <div>
            <div className="s2 grey8" style={{ paddingBottom: "10px" }}>
              Cardholder Name <span className="text-danger">*</span>
            </div>
            <Field
              inputFieldFull
              name="name"
              value={form.name}
              // defaultValue={form.name}
              onChange={(e) => handleChange(e)}
              style={{ border: errors.name ? "1px solid #ff0000" : "" }}
            />
            <span className="text-danger">{errors.name}</span>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div>
            <div
              className="s2 grey8"
              style={{ paddingTop: "25px", paddingBottom: "10px" }}
            >
              Card Number <span className="text-danger">*</span>
            </div>
            <CardNumberElement
              className="stripe-input-field-full"
              options={cardNumberStyle}
              onChange={(e) => {
                if (e?.error) {
                  dispatch(
                    validateStripeForm({
                      name: "cardNumber",
                      value: e?.error?.message,
                    }),
                  );
                } else {
                  dispatch(
                    validateStripeForm({
                      name: "cardNumber",
                      value: false,
                    }),
                  );
                }
              }}
            />
            <span className="text-danger">{errors.cardNumber}</span>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6">
          <div>
            <div
              className="s2 grey8"
              style={{ paddingTop: "25px", paddingBottom: "10px" }}
            >
              Expiry (MM/YY) <span className="text-danger">*</span>
            </div>
            <CardExpiryElement
              className="stripe-input-field-full"
              options={cardStyle}
              onChange={(e) => {
                if (e?.error) {
                  dispatch(
                    validateStripeForm({
                      name: "cardExpiry",
                      value: e?.error?.message,
                    }),
                  );
                } else {
                  dispatch(
                    validateStripeForm({
                      name: "cardExpiry",
                      value: false,
                    }),
                  );
                }
              }}
            />
            <span className="text-danger">{errors.cardExpiry}</span>
          </div>
        </div>
        <div className="col-lg-6">
          <div>
            <div
              className="s2 grey8"
              style={{ paddingTop: "25px", paddingBottom: "10px" }}
            >
              CVC <span className="text-danger">*</span>
            </div>
            <CardCvcElement
              className="stripe-input-field-full"
              options={cardStyle}
              onChange={(e) => {
                if (e?.error) {
                  dispatch(
                    validateStripeForm({
                      name: "cardCVC",
                      value: e?.error?.message,
                    }),
                  );
                } else {
                  dispatch(
                    validateStripeForm({
                      name: "cardCVC",
                      value: false,
                    }),
                  );
                }
              }}
            />
            <span className="text-danger">{errors.cardCVC}</span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div>
            <div
              className="s2 grey8"
              style={{ paddingTop: "25px", paddingBottom: "10px" }}
            >
              Address <span className="text-danger">*</span>
            </div>
            <PlacesAutocomplete
              value={form.address}
              onSelect={handleAddressSelect}
              onChange={handleAddressChange}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                <div
                  style={{
                    border: errors.address ? "1px solid #ff0000" : "",
                    borderRadius: errors.address ? "10px" : "",
                  }}
                >
                  <input
                    {...getInputProps({
                      className: "input-field-full",
                    })}
                  />
                  <div
                    style={{
                      position: "absolute",
                      zIndex: "1",
                      width: "95%",
                      borderBottomLeftRadius: "10px",
                      borderBottomRightRadius: "10px",
                    }}
                  >
                    {/* {loading && <div>Loading...</div>} */}
                    {suggestions.map((suggestion, index) => {
                      return (
                        <div
                          className="input-field-full-list-address"
                          key={index}
                          {...getSuggestionItemProps(suggestion)}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            <span className="text-danger">{errors.address}</span>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6">
          <div>
            <div
              className="s2 grey8"
              style={{ paddingTop: "25px", paddingBottom: "10px" }}
            >
              City <span className="text-danger">*</span>
            </div>
            <Field
              inputFieldFull
              name="city"
              value={form.city}
              onChange={(e) => handleChange(e)}
              style={{ border: errors.city ? "1px solid #ff0000" : "" }}
            />
            <span className="text-danger">{errors.city}</span>
          </div>
        </div>
        <div className="col-lg-6">
          <div>
            <div
              className="s2 grey8"
              style={{ paddingTop: "25px", paddingBottom: "10px" }}
            >
              State / Province <span className="text-danger">*</span>
            </div>
            <Field
              inputFieldFull
              name="state"
              value={form.state}
              onChange={(e) => handleChange(e)}
              style={{ border: errors.state ? "1px solid #ff0000" : "" }}
            />
            <span className="text-danger">{errors.state}</span>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6">
          <div
            className="s1 grey8"
            style={{ paddingTop: "25px", paddingBottom: "10px" }}
          >
            Country <span className="text-danger">*</span>
          </div>

          <CustomSelect
            items={countriesList?.map((country) => {
              return {
                value: country.code,
                label: country.name,
              };
            })}
            value={country}
            onChange={(e) => setCountry(e.target.value.value)}
            menuMaxHeight={"330px"}
            error={errors.country}
          />
          <span className="text-danger">{errors.country}</span>
        </div>
        <div className="col-lg-6">
          <div>
            <div
              className="s2 grey8"
              style={{ paddingTop: "25px", paddingBottom: "10px" }}
            >
              Postal/Zip Code <span className="text-danger">*</span>
            </div>
            <Field
              inputFieldFull
              name="postalCode"
              value={form.postalCode}
              onChange={(e) => handleChange(e)}
              style={{ border: errors.postalCode ? "1px solid #ff0000" : "" }}
            />
            <span className="text-danger">{errors.postalCode}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
