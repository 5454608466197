import React, { useEffect } from "react";
import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Table from "@mui/material/Table";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import TablePagination from "./TablePagination";
import BpCheckbox from "../sub-components/Checkbox";
import TableToolbar from "./Toolbar";
import DelayedSkeletonLoader from "../sub-components/DelayedSkeletonLoader";
import DefaultUser from "../../assets/icons/default_user.svg";

const MuiTable = (props) => {
  const {
    data,
    columns,
    loading,
    checkboxRequired,
    backgroundColor,
    padding,
    checkboxSize,
    page,
    selected,
    displayAvatar,
    displayImage,
    columnIndexToRenderImage,
    handleTableRowClick,
    columnIndexToSearch,
    inputValue,
    filters,
    sortBy,
    onMouseOver,
    onMouseLeave,
    hidePagination,
    customRowPerPage,
    userApplied,
    disablePointer,
  } = props;

  const [rowsPerPage, setRowsPerPage] = React.useState(customRowPerPage || 10);
  const [isEmptyState, setIsEmptyState] = React.useState(false);

  const currentView = useSelector(
    (state) => state.teamsReducer.tableData.currentViewApplied,
  );

  const rows =
    data?.length > 0
      ? data
          ?.filter((val) => {
            // columnIndexToSeach to be sent prop parent i.e the column against which the seach is to be used, default is 0
            // -------------------------------- ASK THE BACKEND PEEPS TO SEND FULL_NAME FROM API TO SEARCH FROM HERE --------------------------------
            const searchBy = val.first_name
              ? val.first_name
              : val?.user_profile?.first_name
                ? val.user_profile.first_name
                : val[columns[columnIndexToSearch || 0].key];

            if (inputValue === "" || !inputValue) {
              return val;
            } else if (
              inputValue &&
              searchBy.toLowerCase().includes(inputValue.toLowerCase())
            ) {
              return val;
            }
          })
          .filter((val) => {
            if (filters && Object.keys(filters).length > 0) {
              const filtersArr = Object.entries(filters);
              return filtersArr.every(([key, values]) => {
                if (
                  Object.prototype.hasOwnProperty.call(val, "team_group") &&
                  key === "type"
                ) {
                  // To access the nested value inside teams
                  return values.includes(val.team_group[0].sync_type);
                }
                return values.includes(val[key]);
              });
            } else {
              return val;
            }
          })
          .map((data) => {
            return data;
          })
      : [];

  let returnRows = sortBy ? rows.sort((a, b) => a[sortBy] - b[sortBy]) : rows;

  if (
    userApplied &&
    userApplied?.name !== "All Users" &&
    currentView?.name === "Teams"
  ) {
    returnRows = returnRows.filter((row) => row?.name === userApplied?.name);
  }

  const remainingEmptyRows =
    page > 0 && Math.max(0, page * rowsPerPage - rows.length);

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    props.setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n);
      props.setSelected(newSelecteds);
      return;
    }
    props.setSelected([]);
  };

  const handleChangePage = (newPage) => {
    const maxPage =
      Math.ceil(rows.length / rowsPerPage) === 0
        ? 1
        : Math.ceil(rows.length / rowsPerPage);
    if (newPage < maxPage && newPage > -1) {
      props.setTablePage(newPage);
    }
  };
  const handleChangePageCustomPagination = (event, value) => {
    props.setTablePage(value);
  };

  const handleChangeRowsPerPage = (event) => {
    // REMOVE THIS LOCAL STATE AND MOVE THIS INTO REDUX
    setRowsPerPage(parseInt(event.target.value, 10));
    props.setTablePage(1);
    if (props.setRowsPerPage) {
      props.setRowsPerPage(parseInt(event.target.value, 10));
    }
  };

  const getHeight = (type, tableCenter) => {
    if (customRowPerPage) {
      if (type === "max") {
        return "372px";
      }
    }

    if (rowsPerPage === 5) {
      if (tableCenter) {
        return "360px";
      }
      if (type === "min") {
        return "440px";
      }
    }
    if (rowsPerPage === 10) {
      if (type === "max") {
        return "calc(100vh - 500px)";
      }
      if (type === "min") {
        return "500px";
      }
    }
    if (rowsPerPage === 25) {
      if (type === "max") {
        return "calc(100vh - 500px)";
      }
      if (type === "min") {
        return "500px";
      }
    }
  };

  const getAvatar = (row, columnKey) => {
    const nameSplit = row.first_name
      ? `${row?.first_name?.substring(0, 1)}${row?.last_name?.substring(0, 1)}`
      : row[columnKey]
          ?.split(" ")
          .map((word) => word.substring(0, 1))
          .join("");

    return (
      <>
        {row.image_url || row.photo ? (
          <div>
            <img
              src={row.image_url || row.photo}
              style={{
                width: "40px",
                height: "40px",
                maxWidth: "40px",
                maxHeight: "40px",
                borderRadius: "100px",
                marginRight: "18px",
              }}
              loading="lazy"
              onError={(e) => {
                e.target.src = DefaultUser;
              }}
            />
          </div>
        ) : (
          <div style={{ display: "flex", margin: 0, alignItems: "center" }}>
            <span
              className="s1 grey1"
              style={{
                marginRight: "18px",
                minWidth: "38px",
                maxWidth: "38px",
                minHeight: "38px",
                maxHeight: "38px",
                background: "#495057",
                color: "#F8F9FA",
                borderRadius: "50%",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {nameSplit}
            </span>
          </div>
        )}
      </>
    );
  };

  const getImage = (row) => (
    <div>
      <img
        style={{
          width: "36px",
          height: "36px",
          maxWidth: "36px",
          maxHeight: "36px",
          marginRight: "18px",
        }}
        src={row.image_url}
      />
    </div>
  );

  const returnEmptyState = () => (
    <TableRow style={{ height: getHeight("min", "tableCenter") }}>
      <TableCell colSpan={8} style={{ border: "none" }} align="center">
        <span className="s2 grey8">
          {props.emptyStateText || "No Data Found"}
        </span>
      </TableCell>
    </TableRow>
  );

  useEffect(() => {
    if (rows.length > 0 && remainingEmptyRows === rowsPerPage) {
      props.setTablePage(page - 1);
    }
  }, [remainingEmptyRows]);

  useEffect(() => {
    if (rows.length === 0 && !isEmptyState) {
      setIsEmptyState(true);
    }
    if (rows.length > 0 && isEmptyState) {
      setIsEmptyState(false);
    }
  }, [rows]);

  return (
    <Box sx={{ width: "100%", mb: 2, position: "relative" }}>
      <Paper
        sx={{ width: "100%", mb: 2 }}
        style={{
          borderTop: "1px solid #E9ECEF",
          borderLeft: "1px solid #E9ECEF",
          borderRight: "1px solid #E9ECEF",
          borderBottom: "1px solid #E9ECEF",
          boxShadow: "none",
          background: "#FFFFFF",
          minWidth: "400px",
        }}
      >
        {selected?.length > 0 && checkboxRequired && !props.disableToolbar && (
          <TableToolbar
            numSelected={selected.length}
            totalRows={data.length}
            selected={selected}
            toolbarText={
              props.toolbarText || ["Data selected", "All data selected"]
            }
            toolbarImage={props.toolbarImage}
            handleToolbarAction={props.handleToolbarAction}
          />
        )}

        <TableContainer
          sx={{
            maxHeight: getHeight("max"),
            minHeight: getHeight("min"),
            backgroundColor: backgroundColor,
          }}
          className="hideScrollBar"
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              minWidth: 750,
              "tr th": {
                // backgroundColor: backgroundColor,
                backgroundColor: props.headerBackgroundColor,
                borderBottom: "1px solid #BFBFBF",
                height: "80px",
                padding: padding,
              },
              "tr td": {
                border: "1px solid transparent",
                borderStyle: "solid none",
                borderBottom: "1px solid #BFBFBF",
                boxSizing: "border-box",
                height: "72px",
                backgroundColor: backgroundColor,
                padding: padding,
              },

              // when rows are less than rowsPerPage i.e whiteSpace, give independent border
              [`tr:nth-of-type(${rowsPerPage - remainingEmptyRows}) td`]: {
                borderBottom:
                  rows.length / rowsPerPage >= page &&
                  rowsPerPage - remainingEmptyRows !== 1
                    ? "1px solid transparent"
                    : "1px solid #BFBFBF",
              },
              "tr:first-of-type td": {
                borderBottom:
                  rowsPerPage - remainingEmptyRows === 1
                    ? "1px solid #F8F9FA"
                    : "1px solid #BFBFBF",
              },
              "tr:hover td": {
                cursor: disablePointer ? "not-allowed" : "pointer",
                border: "1px solid #E9ECEF",
                borderStyle: "solid none",
                background:
                  "linear-gradient(0deg, rgba(244, 242, 249, 0.4), rgba(244, 242, 249, 0.4)), #FFFFFF",
                // maxHeight:"72px"
              },
              // next sibling of current element
              "tr:hover + tr td": {
                borderTop: "1px solid #BFBFBF",
              },
              "tr:last-child td": {
                borderBottom: "1px solid #BFBFBF",
              },
              "tr:last-child:hover td": {
                borderBottom: "1px solid #BFBFBF",
              },
            }}
          >
            <TableHead className="head6 grey8">
              <TableRow>
                {checkboxRequired && columns?.length ? (
                  <TableCell
                    padding="checkbox"
                    style={{ paddingLeft: "28px", minWidth: "70px" }}
                  >
                    <BpCheckbox
                      checked={
                        rows.length > 0 && selected?.length === rows.length
                      }
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize:
                            Number(checkboxSize?.substring(0, 2)) + 8 || 20,
                        },
                      }}
                      indeterminate={false}
                      onChange={handleSelectAllClick}
                      width={checkboxSize || 20}
                      height={checkboxSize || 20}
                      style={{ padding: 0, display: "flex", margin: "0" }}
                    />
                  </TableCell>
                ) : null}
                {columns.map((column) => (
                  <TableCell
                    key={column.key}
                    align={column.align}
                    style={{
                      padding: column.padding,
                      minWidth: column.minWidth,
                    }}
                  >
                    <span
                      className={`body1-semi-bold ${
                        props.headingColor ? props.headingColor : "grey8"
                      }`}
                    >
                      {column.name}
                    </span>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            {isEmptyState && !loading ? (
              returnEmptyState()
            ) : (
              <TableBody>
                {loading
                  ? Array(rowsPerPage)
                      .fill()
                      .map((_, i) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            className="rrr"
                            tabIndex={-1}
                            key={i}
                          >
                            {checkboxRequired && (
                              <TableCell padding="checkbox"></TableCell>
                            )}

                            {Array(columns.length - 1)
                              .fill()
                              .map((_, i) => {
                                return (
                                  <TableCell key={i}>
                                    <DelayedSkeletonLoader type="text-sm" />
                                  </TableCell>
                                );
                              })}

                            <TableCell align="center">
                              <div
                                style={{
                                  paddingLeft:
                                    columns.length === 4 ? "75px" : "95px",
                                }}
                              >
                                <DelayedSkeletonLoader type="text-sm" />
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  : returnRows
                      ?.slice(
                        (page - 1) * rowsPerPage,
                        (page - 1) * rowsPerPage + rowsPerPage,
                      )
                      .map((row, index) => (
                        <TableRow
                          key={index}
                          onClick={() =>
                            handleTableRowClick && handleTableRowClick(row)
                          }
                          onMouseOver={() => onMouseOver && onMouseOver(row)}
                          onMouseLeave={() => onMouseLeave && onMouseLeave()}
                          style={{ height: "72px" }}
                        >
                          {checkboxRequired && (
                            <TableCell
                              padding="checkbox"
                              onClick={(event) => event.stopPropagation()}
                              style={{ paddingLeft: "28px" }}
                            >
                              <BpCheckbox
                                checked={selected?.indexOf(row) !== -1}
                                onClick={(event) => handleClick(event, row)}
                                width={checkboxSize || 20}
                                height={checkboxSize || 20}
                                style={{}}
                              />
                            </TableCell>
                          )}

                          {columns.map((column) => {
                            return (
                              <>
                                <TableCell
                                  key={column.key}
                                  align={column.align}
                                  style={{
                                    padding: column.padding,
                                    cursor: row?.disablePointer
                                      ? "not-allowed"
                                      : "pointer",
                                  }}
                                >
                                  <div style={{ display: "inline-flex" }}>
                                    {displayAvatar &&
                                      column.key === columns[0].key &&
                                      getAvatar(row, column.key)}
                                    {displayImage &&
                                      column.key ===
                                        columns[columnIndexToRenderImage].key &&
                                      getImage(row, column.key)}

                                    {/* Using accessor for nested values */}
                                    {column.accessor ? (
                                      <span
                                        className={`b1  ${
                                          props.tableTextColor
                                            ? props.tableTextColor
                                            : "grey8"
                                        } d-flex align-items-center`}
                                      >
                                        {column.accessor(row)}
                                      </span>
                                    ) : (
                                      <span
                                        className={`b1 ${
                                          props.tableTextColor
                                            ? props.tableTextColor
                                            : "grey8"
                                        }  d-flex align-items-center`}
                                      >
                                        {row[column?.key]}
                                      </span>
                                    )}
                                  </div>
                                </TableCell>
                              </>
                            );
                          })}
                        </TableRow>
                      ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        {!hidePagination && (
          <TablePagination
            rowsPerPage={rowsPerPage}
            isRowEmpty={returnRows.length / rowsPerPage >= page + 1}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            handleChangePageCustomPagination={handleChangePageCustomPagination}
            renderPage={page}
            rows={returnRows}
            backgroundColor={backgroundColor}
          />
        )}
      </Paper>
    </Box>
  );
};

export { MuiTable as Table };
